<template>
  <TTView>
    <VRow>
      <VCol>
        <AssessmentForm
          :entity="skillAssessment"
          :skills="skills"
          :loading="loading"
          @update:name="skillAssessment.name = $event"
          @update:description="skillAssessment.description = $event"
          @update:skillId="skillAssessment.skillId = $event"
          @update:settings:cooldown="skillAssessment.settings.cooldown = $event"
          @update:searchSkills="fetchSkill($event)"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AssessmentForm from '../../components/expert-assessment/AssessmentForm.vue';

export default {
  name: 'AssessmentCreate',

  components: {
    AssessmentForm,
  },

  data() {
    return {
      skillAssessment: {
        skillId: '',
        name: '',
        description: '',
        settings: {
          cooldown: 0,
        },
      },
      skills: [],
      loading: false,
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const { skillAssessment } = this;
        const data = { skillAssessment };

        await this.$di.api.ExpertAssessment.skillAssessmentCreate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },

    async fetchSkill(query = '') {
      try {
        this.loading = true;

        const { skills } = await this.$di.api.CompetencyMatrix.SkillsSearch({ query });

        this.skills = skills;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
